<template>
  <div class="tabs">
    <div class="tabs__categories d-flex">
      <div
        v-for="(tab, index) in props.data"
        :key="index"
        :class="[
          'tabs__categories__tab',
          'text-center',
          'px-4 py-2',
          'd-flex flex-v-center',
          { 'tabs__categories__tab--active': activeTab === index },
        ]"
        tabindex="0"
        data-gtm-action="tabs"
        :data-gtm-label="tab.title"
        @click="toggleTab(index)"
        @keyup.enter="toggleTab(index)"
        v-html="tab.title"
      />
    </div>
    <div class="tabs__content p-4" v-text="activeContent" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

interface tab {
  title: string
  content: string
}
const props = defineProps<{
  data: tab[]
}>()

const toggleTab = (index: number) => {
  activeTab.value = index
}

const activeTab = ref(0)

const activeContent = computed(() => {
  return props.data[activeTab.value].content
})
</script>

<style lang="scss">
.tabs {
  border: 1px solid $color-secondary;

  &__categories {
    justify-content: space-between;

    &__tab {
      cursor: pointer;
      flex: 1;
      border-bottom: 1px solid $color-secondary;

      &:not(:last-of-type) {
        border-right: 1px solid $color-secondary;
      }

      &--active {
        font-weight: 700;
        border-bottom-color: transparent;
        background-color: rgba($color-primary, 0.1);
      }
    }
  }

  &__content {
    background-color: rgba($color-primary, 0.1);
  }
}
</style>
