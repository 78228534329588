<template>
  <div class="norli-accordion">
    <div class="norli-accordion__categories d-flex mb-3">
      <div
        v-for="(cat, index) in accordionDataModel"
        :key="'cat' + index"
        :class="[
          'norli-accordion__categories__item button mx-1',
          { 'button--solid-white': index === activeGroupIndex },
        ]"
        data-gtm-action="accordion category"
        :data-gtm-label="cat.title"
        @click="setActiveGroup(index)"
        @keyup.enter="setActiveGroup(index)"
        v-text="cat.title"
      />
    </div>

    <template v-for="(group, i) in accordionDataModel">
      <div v-if="group.isActive" :key="'group' + i" class="norli-accordion__container">
        <div
          v-for="(item, i) in group.accordionItems"
          :key="'item' + i"
          :class="['norli-accordion__item', { 'norli-accordion__item--active': item && item.isOpen }]"
        >
          <div
            class="norli-accordion__item__title bg-white"
            role="button"
            tabindex="0"
            data-gtm-action="accordion question"
            :data-gtm-label="item.question"
            @click="toggleAccordion(i)"
            @keyup.enter="toggleAccordion(i)"
            v-html="item.question"
          />
          <transition name="slide">
            <div v-show="item && item.isOpen" class="bg-lighten">
              <div class="norli-accordion__item__body">
                <div v-html="item.answer" />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

interface accordionCategory {
  title: string
  accordionItems: accordionItem[]
}

interface accordionItem {
  question: string
  answer: string
}

const props = defineProps<{
  data: accordionCategory[]
}>()

const activeGroupIndex = computed(() => {
  return accordionDataModel.value.findIndex((group: any) => group.isActive)
})

const accordionDataModel = ref(
  props.data.map((categories: accordionCategory, index) => {
    return {
      title: categories.title,
      isActive: index === 0 ? true : false,
      accordionItems: categories.accordionItems.map((item: accordionItem) => {
        return {
          ...item,
          isOpen: false,
        }
      }),
    }
  })
)

const toggleAccordion = (index: number) => {
  const toggleItem = accordionDataModel.value[activeGroupIndex.value].accordionItems[index]
  const value = toggleItem.isOpen === undefined || toggleItem.isOpen === false ? true : false

  toggleItem.isOpen = value
}

const setActiveGroup = (index: number) => {
  accordionDataModel.value.forEach((group: any, i: number) => {
    group.isActive = index === i ? true : false
  })
}
</script>

<style lang="scss">
.slide-enter-active {
  transition-duration: 0.6s;
  transition-timing-function: ease;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
  max-height: 700px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.norli-accordion {
  $accordionTitle: '';
  $accordionBody: '';
  $chevronIcon: '';

  &__categories {
    justify-content: center;

    &__item {
      cursor: pointer;
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }

  &__item {
    &:not(:first-of-type) {
      margin-top: $spacer-md;
    }

    &__title {
      $accordionTitle: &;
      background-color: $color-white;
      padding: $spacer-lg;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: 700;

      &::after {
        $chevronIcon: &;
        content: '';
        -webkit-mask: url(/src/assets/svg/chevron.svg) center center no-repeat;
        background-color: $color-primary;
        height: $spacer-lg;
        width: $spacer-lg;
        margin-left: $spacer-sm;
        transform: rotate(180deg);
        transition: transform 0.3s ease;
        will-change: rotate;
        flex-shrink: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      // &:focus {
      //   position: relative;
      //   outline: 1px dashed $color-secondary;
      //   z-index: 3;
      // }
    }

    &__body {
      $accordionBody: &;
      padding: $spacer-lg;
    }

    &--active {
      #{$chevronIcon} {
        transform: rotate(0);
      }
    }
  }
}

.bg-blue {
  // override automatic white text color for children for navy background
  .bg-white {
    color: var(--color-text, $color-primary);
  }

  .ab-accordion__tabs__item button {
    color: $color-white;
  }
}

.bg-lighten {
  // overlay to simulate 50% background color opacity for accordion items
  position: relative;
  color: $color-black;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba($color-white, 0.75);
  }

  & > * {
    // position content above overlay
    position: relative;
    z-index: 1;
  }
}
</style>
