<template>
  <!-- Header -->
  <header class="container flex-v-center header">
    <div class="grid">
      <div class="span12 flex-v-center">
        <a href="/" title="Gå til top" data-gtm-action="header navigation" data-gtm-label="hjem">
          <inline-svg class="logo" :src="prefixAssetUrl(norligLogo)" width="150" height="30" aria-label="Norli logo"
        /></a>

        <nav class="d-flex flex-h-right nav">
          <a
            class="button button--cta ml-4"
            href="/mit-norli"
            data-gtm-action="header navigation"
            data-gtm-label="log på"
            >Log på</a
          >
        </nav>
      </div>
    </div>
  </header>
  <main class="main">
    <!-- Video -->
    <div class="video container py-6 bg-tertiary">
      <div class="grid">
        <div class="span8 move2">
          <video controls :autoplay="autoplay" :muted="autoplay" v-html="videoSrcHtml">
          </video>
        </div>
    </div>
    </div>
  </main>
  <footer-component />
</template>

<script setup lang="ts">
import { computed, ref, Ref, onMounted, inject } from 'vue'
import norligLogo from '@/assets/svg/norli-logo.svg'
import FooterComponent from '@/components/Footer/Footer.vue'

const assetUrlPrefix = inject('assetUrlPrefix') as string

const prefixAssetUrl = (url: string): string => {
  if (import.meta.env.DEV) return url

  const filename = url.slice(2)
  return `${assetUrlPrefix}/${filename}`
}

let autoplay = ref(false)

const videoSrcHtml = '<source src="/siteassets/site-images/video/norli-animation.mp4" type="video/mp4" />';

onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  const isAutoplay = params.get('autoplay');
  autoplay.value = isAutoplay === '1';
});
</script>
  
<style lang="scss">
@import '@/assets/scss/imports/colors.scss';
@import '@/assets/scss/imports/sizes.scss';

video {
  width: 100%;
}

#kontakt {
  margin-top: 0;
}
</style>

