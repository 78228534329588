<template>
    <footer id="kontakt" class="bg-primary mt-5">
        <div class="container py-7">
            <div class="grid">
                <div class="span12 text-center mb-5">
                    <h2>Kontakt Norli Liv og Pension</h2>
                </div>
                <div class="span3 span6--md">
                    <inline-svg class="footer-logo" :src="prefixAssetUrl(norligLogo)" width="90" fill="white"
                        aria-label="Norli logo" />
                    <p>
                        Norli Liv og Pension Livsforsikring A/S<br />
                        Midtermolen 1<br />
                        2100 København Ø<br />
                        CVR 64 14 57 11
                    </p>
                    <p>
                        <a href="https://www.linkedin.com/company/norli-liv-pension/about/" data-gtm-action="footer"
                            data-gtm-label="linkedin" target="_blank">
                            <inline-svg :src="prefixAssetUrl(iconLinkedin)" width="40" height="40" />
                        </a>
                    </p>
                </div>
                <div class="span3 span6--md">
                    <h4>Ring til os</h4>
                    <h2 class="mt-1 mb-2">
                        <a href="tel:38202400" data-gtm-action="footer" data-gtm-label="38 20 24 00">38 20 24 00</a>
                    </h2>
                    <p>
                        Man.-tors. | 8.30-16<br />
                        Fredag | 8.30-15<br />
                        Weekend | Lukket
                    </p>
                </div>
                <div class="span3 span6--md">
                    <h4>Skriv til os</h4>
                    <h2 class="mt-1 mb-2">
                        <a href="mailto:service@norli.dk" data-gtm-action="footer"
                            data-gtm-label="service@norli.dk">service@norli.dk</a>
                    </h2>
                    <p>Vi besvarer din henvendelse<br />inden for 5 hverdage</p>
                </div>
                <div class="span3 span6--md">
                    <h4>Norli Sundhed</h4>
                    Hvis du har spørgsmål til din sundhedsforsikring:
                    <h2 class="mt-1 mb-2">
                        <a href="tel:70131303">70 13 13 03</a><br>
                        <a href="mailto:norli@sundhedsforsikring.nu">norli@sundhedsforsikring.nu</a>
                    </h2>
                    <p>
                        <a href="https://www.if.dk/erhverv/partner/norli/norli-sundhed">Anmeld&nbsp;en&nbsp;skade&nbsp;på&nbsp;din&nbsp;sundhedsforsikring&nbsp;her</a>
                    </p>
                </div>
                <div v-if="isMobile" class="span3 span6--md">
                    <h4>Presse</h4>
                    <h2 class="mt-1 mb-2"><a href="tel:30 53 40 01">30 53 40 01</a></h2>
                    <p>Søren Andersen, Group CEO</p>
                </div>
            </div>
        </div>
        <div v-if="!isMobile" class="container bg-primary">
            <div class="grid">
                <div class="span12">
                    <p>Presse: Søren Andersen, Group CEO; tlf.: <a href="tel:30534001">30 53 40 01</a></p>
                </div>
            </div>
        </div>
        <div class="container bg-secondary">
            <div class="grid">
                <div class="span12 d-flex flex-wrap">
                    <a v-for="(link, i) in footerLinks" :key="i" :href="link.url" :title="link.title" target="_blank"
                        class="py-2 mr-5" v-text="link.title" data-gtm-action="footer" :data-gtm-label="link.title" />
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue'
import { useStore } from 'vuex'
import iconLinkedin from '@/assets/svg/logo-linkedin.svg'
import norligLogo from '@/assets/svg/norli-logo.svg'

const store = useStore();

const assetUrlPrefix = inject('assetUrlPrefix')

const prefixAssetUrl = (url: string): string => {
    if (import.meta.env.DEV) return url

    const filename = url.slice(2)
    return `${assetUrlPrefix}/${filename}`
}

const isMobile = computed(() => {
  return store.state.windowWidth < 920
})

const footerLinks = [
    {
        title: 'Whistleblowerordning',
        url: '/siteassets/site-images/pdf/whistleblowerordning/om-norli-liv-og-pensions-whistleblowerordning.pdf',
    },
    {
        title: 'Indberet hændelse',
        url: 'https://report.whistleb.com/da/norlipension',
    },
    {
        title: 'Klagevejledning',
        url: `/siteassets/site-images/pdf/klagevejledning/klagevejledning-norli-liv-og-pension.pdf`,
    },
    {
        title: 'Særlige undersøgelser',
        url: `/siteassets/site-images/pdf/klagevejledning/sarlige-undersogelser.pdf`,
    },
]
</script>
