import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Frontpage from '@/views/pages/FrontPage.vue'
import ConfirmationPage from '@/views/pages/ConfirmationPage.vue'
import DiabetesForeningen from '@/views/pages/DiabetesForeningen.vue'
import ForenedeGruppeliv from '@/views/pages/ForenedeGruppeliv.vue'
import VideoPage from '@/views/pages/VideoPage.vue'

import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'FrontPage',
    path: '/',
    component: Frontpage,
  },
  {
    name: 'ConfirmationPage',
    path: '/takfordinunderskrift',
    component: ConfirmationPage,
  },
  {
    name: 'DiabetesForeningenPage',
    path: '/diabetesforeningen',
    component: DiabetesForeningen,
  },
  {
    name: 'VideoPage',
    path: '/her',
    component: VideoPage,
  },
  {
    name: 'ForenedeGruppelivPage',
    path: '/forenede-gruppeliv',
    component: ForenedeGruppeliv,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return to scrolled position using browser history back
    return new Promise((resolve) => {
      setTimeout(() => {
        let position = { left: 0, top: 0 }
        if (savedPosition) position = savedPosition
        resolve(position)
      }, 320) // match page transition duration + small render delay for consistent positioning
    })
  },
})

router.beforeEach((to, from, next) => {
  // set timing marker for page speed tracking between routes
  // eslint-disable-next-line
  if (store.state.allowTracking && (window as any).dataLayer) window.performance.mark('betweenRoutesMark')
  next()
})

router.afterEach(() => {
  if (store.state.isMenuOpen) store.commit('toggleMenu', false)
})

export default router
