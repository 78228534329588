import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { App } from 'vue'
import { Router } from 'vue-router'

/* eslint-disable */
function createAppInsights(options: AppInsightsOptions) {
  const dataAppInsightsKey = document.querySelector('#app')?.getAttribute('data-appinsights-key') ?? ''
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: options.instrumentationKey ?? dataAppInsightsKey,
      //enabling this causes double tracking of a pageview
      enableAutoRouteTracking: false,
    },
  })

  appInsights.loadAppInsights()

  return appInsights
}

export default function install(app: App, options: AppInsightsOptions) {
  const appInsights = createAppInsights(options)

  options.router.afterEach(async (to, from) => {
    //this is required for the DXP billing
    appInsights.trackPageView({
      name: document.title,
      refUri: from.fullPath,
    })
  })
  app.provide('appInsights', appInsights)
}

export interface AppInsightsOptions {
  instrumentationKey?: string
  router: Router
}
