<template>
  <div
    ref="smartImgEl"
    v-inview="{ offset: `${props.preloadOffset}px` }"
    :class="['smart-img', { 'smart-img--bg': asBgImg, 'smart-img--placeholder': imgError }]"
    :style="aspectRatioStyle"
    @intersecting="enteredView"
  >
    <div
      ref="thumbImgEl"
      :style="focalPointPosition"
      :class="['smart-img__thumb', `smart-img__thumb--${bgSize}`, { 'smart-img__thumb--rem0ve': imgLoaded }]"
      :data-src="thumbImgUrl"
    />
    <div
      v-if="hasImgSize"
      ref="fullImgEl"
      :style="focalPointPosition"
      :class="['smart-img__full', `smart-img__full--${bgSize}`]"
      :data-src="responsiveImgUrl"
    />
  </div>
</template>

<script setup lang="ts">
/* eslint-disable */
import { onMounted, onBeforeMount, ref, reactive, computed, nextTick } from 'vue'
import { isEmpty } from 'lodash-es'
import { FocalPoint } from '@/types'

interface Props {
  url: string
  alt?: string
  height: number
  width: number
  focalPoint?: FocalPoint
  ratio?: string
  preloadOffset?: number
  type?: 'inline' | 'bg'
}
const props = withDefaults(defineProps<Props>(), {
  preloadOffset: 250, // trigger preload 250px before enter viewport
  type: 'inline',
})

const smartImgEl = ref<HTMLElement>()
const thumbImgEl = ref<HTMLElement>()
const fullImgEl = ref<HTMLElement>()

let imgHeight = ref(0)
let imgWidth = ref(0)
const ratioPadding = Math.round((props.height / props.width) * 100 * 100) / 100
const thumbImg = reactive({
  width: 256,
  height: 256 / (props.width / props.height),
  quality: 30,
})
let imgLoaded = ref(false)
let imgError = ref(false)
const imgSizes = [500, 1024, 1280, 1600, 1920]
const imgQuality = 92
let hasImgSize = ref(false)
let listeners = reactive(new Map()) // map eventlisteners functions for unmounting
let pixelRatio = ref(0)

const thumbImgUrl = computed((): string => {
  return `${props.url}?width=${thumbImg.width}&quality=${thumbImg.quality}`
})
const responsiveImgUrl = computed((): string => {
  return imgWidth ? `${props.url}?width=${closest.value}&quality=${imgQuality}` : props.url
})
const closest = computed((): number | null => {
  const closestPreset = imgSizes.filter((num) => num >= imgWidth.value!)
  let imgSize = closestPreset.length ? closestPreset[0] : imgSizes[imgSizes.length - 1]

  // get one imgsize bigger if available and screen is retina
  if (isRetina && closestPreset.length >= 2) imgSize = closestPreset[1]

  return imgWidth ? imgSize : null
})
const hasFocalPoint = computed((): boolean => {
  return !isEmpty(props.focalPoint)
})
const focalPointPosition = computed((): string => {
  return hasFocalPoint.value
    ? `background-position: ${+props.focalPoint!.x! * 100}% ${+props.focalPoint!.y! * 100}%`
    : ''
})
const aspectRatioStyle = computed((): string => {
  if (props.type === 'inline') {
    if (props.ratio) {
      const splitFration = props.ratio?.split('/')
      const parsedFraction = +splitFration[0] / +splitFration[1]
      return `padding-bottom: ${(1 / parsedFraction) * 100}%`
    }
    return `padding-bottom: ${ratioPadding}%`
  }
  return ''
})
const asBgImg = computed((): boolean => {
  return props.type === 'bg'
})
const isRetina = computed((): boolean => {
  return pixelRatio.value >= 2
})
const bgSize = computed((): string => {
  return props.type === 'inline' && !props.ratio ? 'contain' : 'cover'
})

const removeThumbnail = () => {
  setTimeout(() => {
    const thumbEl = thumbImgEl.value
    if (!thumbEl) return
    thumbEl.remove()
  }, 600)
}
const enteredView = () => {
  loadImage(thumbImgEl.value as HTMLElement)
    .then(() => {
      hasImgSize.value = true
      imgLoaded.value = false

      nextTick(() => {
        loadImage(fullImgEl.value as HTMLElement)
          .then(() => {
            imgLoaded.value = true

            removeThumbnail()
          })
          .catch(() => {
            imgError.value = true
          })
      })
    })
    .catch(() => {
      imgError.value = true
    })
}
const loadImage = (elem: HTMLElement) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    const imageSrc = elem.dataset.src!

    if (!imageSrc) return

    image.addEventListener('load', resolve)
    image.addEventListener('error', reject)
    image.src = imageSrc
    listeners.set(image, resolve).set(image, reject)

    elem.style.backgroundImage = `url(${imageSrc})`
    delete elem.dataset.src
  })
}

onMounted(() => {
  // TODO add animation option
  // TODO add critical option, no thumb img
  // TODO add maybe full option?
  // TODO get biggest size vmax and dertermine size
  // TODO resize event to get new size
  imgWidth.value = smartImgEl.value!.offsetWidth
  imgHeight.value = smartImgEl.value!.offsetHeight
})
onBeforeMount(() => {
  pixelRatio.value = window.devicePixelRatio
  listeners.forEach((value, key) => key.removeEventListener('load', value))
})
</script>

<style lang="scss">
.smart-img {
  position: relative;
  width: 100%;
  overflow: hidden;

  &--bg {
    height: 100%;
  }

  &--placeholder {
    background-color: $color-grey-100;
    border: 2px solid $color-grey-200;

    &::before {
      content: '';
      border: 2px solid $color-grey-200;
      border-radius: 50%;
      color: $color-grey-200;
      font-size: $font-size-lg;
      font-family: sans-serif;
      height: $spacer-xl;
      width: $spacer-xl;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      height: $spacer-lg;
      width: $spacer-lg;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      background: linear-gradient(to bottom, transparent 45%, $color-grey-200 45%, $color-grey-200 55%, transparent 55%),
        linear-gradient(to right, transparent 45%, $color-grey-200 45%, $color-grey-200 55%, transparent 55%);
    }
  }

  &__thumb,
  &__full {
    position: absolute;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;

    &--cover {
      background-size: cover;
    }

    &--contain {
      background-size: contain;
    }
  }

  &__thumb {
    filter: blur(10px);
    z-index: z(element, default);

    &--rem0ve {
      transition: opacity $transition-dur linear, transform 0s linear;
      transform: translate3d(0, 0, 0);
      will-change: opacity, transform;
      opacity: 0;
      position: absolute;
    }
  }
}
</style>
