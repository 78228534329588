import { inject } from 'vue'

export const usePrefixAssetUrl = (url: string): string => {
  const assetUrlPrefix = inject('assetUrlPrefix') as string

  if (import.meta.env.DEV) return url

  const filename = url.slice(2)

  return `${assetUrlPrefix}/${filename}`
}
