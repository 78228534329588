<template>
  <header class="container flex-v-center header">
    <div class="grid">
      <div class="span12 flex-v-center">
        <a href="/" title="Gå til top" data-gtm-action="header navigation" data-gtm-label="hjem">
          <inline-svg class="logo" :src="prefixAssetUrl(norligLogo)" width="150" height="30" aria-label="Norli logo"
        /></a>

        <nav class="d-flex flex-h-right nav">
          <a
            class="button button--cta ml-4"
            href="/mit-norli"
            data-gtm-action="header navigation"
            data-gtm-label="log på"
            >Log på</a
          >
        </nav>
      </div>
    </div>
  </header>

  <div class="container mt-7">
    <div class="grid">
      <div class="span12 text-center my-7">
        <h1>Tak for din underskrift</h1>
      </div>
    </div>
  </div>

  <footer-component />
</template>

<script setup lang="ts">
import { inject } from 'vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import norligLogo from '@/assets/svg/norli-logo.svg'
import iconLinkedin from '@/assets/svg/logo-linkedin.svg'

const assetUrlPrefix = inject('assetUrlPrefix')

const prefixAssetUrl = (url: string): string => {
  if (import.meta.env.DEV) return url

  const filename = url.slice(2)
  return `${assetUrlPrefix}/${filename}`
}
</script>

