<template>
  <div class="form__group form__group--checkbox">
    <label
      class="form__custom-checkbox"
      :class="{ 'form__custom-checkbox--invalid': validator.$dirty && validator.$invalid }"
    >
      <input :id="id" type="checkbox" :value="value" :checked="modelValue == value" @change="onInput($event)" />
      <i></i>
    </label>
    <label class="form__label" :for="id">
      {{ label }}
    </label>
    <div class="form__error" :for="id">
      <template v-for="error of validator.$errors" :key="error.$uid">
        {{ error.$message }}
      </template>
    </div>
  </div>
</template>
  
<script setup lang="ts">
const props = defineProps<{
  value: boolean | string | number
  modelValue: boolean
  validator: any
  id: string
  label?: string
}>()

const emit = defineEmits(['update:modelValue', 'change'])

const onInput = (e: any) => {
  props.validator.$touch()
  emit('update:modelValue', e.target.checked ? props.value : false)
  emit('change')
}
</script>