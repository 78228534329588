/* eslint-disable no-unused-vars */
import { createApp } from 'vue'
import { toPairs } from 'lodash-es'
import InlineSvg from 'vue-inline-svg'
import App from '@/App.vue'
import inView from '@/directives/inView'
import router from '@/router'
import store from '@/store'
import appInsights, { AppInsightsOptions } from './appinsights'
import SmartLink from '@/components/SmartLink/SmartLink.vue'
import SmartImage from '@/components/SmartImage/SmartImage.vue'
import SmartSvg from '@/components/SmartSvg/SmartSvg.vue'

import '/src/assets/scss/framework.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(inView)
  .use(appInsights, { router } as AppInsightsOptions)
  .component('inline-svg', InlineSvg)
  .component('smart-link', SmartLink)
  .component('smart-image', SmartImage)
  .component('smart-svg', SmartSvg)

// Register all Episerver view components globally.
// Otherwise we need to register all components manually here in main.js.
const blocks = import.meta.globEager('./views/blocks/*.vue')
const pages = import.meta.globEager('./views/pages/*.vue')

const registerComponents = (components: Object) => {
  toPairs(components).forEach(([path, moduleDefinition]) => {
    // Get name of component, based on filename
    const componentName = path
      .split('/')
      .pop()!
      .replace(/\.\w+$/, '')

    // Register component on this Vue instance
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    app.component(componentName, moduleDefinition.default || moduleDefinition)
  })
}

;[blocks, pages].forEach((comps) => {
  registerComponents(comps)
})


const assetsUrl = document.getElementById('app')?.dataset.assetsUrl || '';

app.provide('assetUrlPrefix', assetsUrl)

app.mount('#app')

declare global {
  interface Window {
    epi: {
      inEditMode: boolean
      isEditable: boolean
      subscribe(eventName: string, callback: (message: { url: string }) => void): void
      ready: boolean
    },
    showBanner: boolean,
    bannerText: string,
    bannerColor: string
  }
}
