import { createStore } from 'vuex'

export type CmsRootState = {
  isMenuOpen: boolean
  isModalOpen: boolean
  windowWidth: number
  scrollPosition: number
  isTouchDevice: boolean
  allowTracking: boolean
  isCalculatorOpen: boolean
}

const rootState = {
  isMenuOpen: false,
  isModalOpen: false,
  windowWidth: 0,
  scrollPosition: 0,
  isTouchDevice: false,
  allowTracking: false,
  isCalculatorOpen: false,
} as CmsRootState

export default createStore<CmsRootState>({
  state: rootState,
  mutations: {
    toggleMenu(state, payload) {
      state.isMenuOpen = payload
    },
    toggleModal(state, payload) {
      state.isModalOpen = payload
    },
    setWindowWidth(state, payload) {
      state.windowWidth = payload
    },
    setScrollPosition(state, payload) {
      state.scrollPosition = payload
    },
    setDeviceType(state, payload) {
      state.isTouchDevice = payload
    },
    setAllowTracking(state, payload) {
      state.allowTracking = payload
    },
    toggleCalculator(state, payload) {
      state.isCalculatorOpen = payload
    }
  },
  actions: {},
  modules: {},
})
