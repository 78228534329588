<template>
  <div class="form__group">
    <label class="form__label" :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      class="form__input"
      :class="{ 'form__input--invalid': validator.$dirty && validator.$invalid }"
      type="text"
      :value="modelValue"
      @input="onInput($event)"
      :placeholder="placeholder"
    />
    <div class="form__error">
      <template v-for="error of validator.$errors" :key="error.$uid">
        {{ error.$message }}
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: string
    validator: any
    id: string
    label?: string
    placeholder?: string
  }>(),
  {
    modelValue: '',
    label: 'Label',
    placeholder: '',
  }
)

const emit = defineEmits(['update:modelValue', 'input'])

const onInput = (e: any) => {
  props.validator.$touch()
  emit('update:modelValue', e.target.value)
  emit('input')
}
</script>