<template>
  <div ref="svgEl" />
</template>

<script setup lang="ts">
import { ref, onMounted, useAttrs } from 'vue'
const props = defineProps<{
  url: string
  height?: number
  width?: number
}>()

const attrs = useAttrs()
const svgEl = ref<HTMLElement>()
const inlineSvg = ref()

const insertAttrsAt = (index: number) => {
  let attributes = ''

  for (const key of Object.keys(attrs)) {
    attributes += `${key}="${attrs[key]}" `
  }

  inlineSvg.value = inlineSvg.value.substr(0, index) + attributes + inlineSvg.value.substr(index)
}

const fetchSvg = (url: string) => {
  fetch(url)
    .then((r) => r.text())
    .then((text) => {
      inlineSvg.value = text

      const svgElPos = text.indexOf('<svg ') + 5
      if (svgElPos > -1 && attrs) {
        // add attrs to inline svg
        insertAttrsAt(svgElPos)
      }

      // add inline svg as sibling to svgEl div, to prevent nesting causing styling issues
      svgEl.value?.insertAdjacentHTML('beforebegin', inlineSvg.value)
      svgEl.value?.remove()
    })
    .catch(console.error.bind(console))
}

onMounted(() => {
  fetchSvg(props.url)
})
</script>
