<template>
  <span class="link-col">
    <span
      :class="['link-col__title', { 'link-col__title--active': isActive }]"
      tabindex="0"
      data-gtm-action="link collection"
      :data-gtm-label="props.data.title"
      @click.self="toggle"
      @keyup.enter="toggle"
      v-text="props.data.title"
    />

    <transition name="slide">
      <div v-if="isActive" class="link-col__links d-flex flex-col">
        <a
          v-for="(link, index) in props.data.collection"
          :key="index"
          :href="link.url"
          class="link-col__links__link"
          target="_blank"
          data-gtm-action="link"
          :data-gtm-label="link.title"
          v-text="link.title"
        />
      </div>
    </transition>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface link {
  title: string
  url: string
}
interface collection {
  title: string
  collection: link[]
}
const props = defineProps<{
  data: collection
}>()

let isActive = ref(false)

const toggle = () => {
  isActive.value = !isActive.value
}
</script>

<style lang="scss">
.link-col {
  text-decoration: underline;
  cursor: pointer;

  &__title {
    position: relative;
    padding-right: $spacer-lg + $spacer-xs;

    &::after {
      $chevronIcon: &;
      content: '';
      -webkit-mask: url(/src/assets/svg/chevron.svg) center center no-repeat;
      background-color: $color-primary;
      height: $spacer-lg;
      width: $spacer-lg;
      margin-left: $spacer-sm;
      transform: rotate(180deg);
      transition: transform 0.3s ease;
      will-change: rotate;
      flex-shrink: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--active::after {
      transform: rotate(0);
    }
  }

  &__links {
    margin-top: $spacer-xs;
    background-color: $color-tertiary;
    padding: 0 $spacer-md;

    &__link {
      display: block;
      margin: $spacer-sm 0;

      &:first-of-type {
        margin-top: $spacer-md;
      }

      &:last-of-type {
        margin-bottom: $spacer-md;
      }
    }
  }
}
</style>
