<template>
  <div class="d-flex flex-grow">
    <div class="h-48 flex-grow">
      <div class="slider" style="--step: 10; --min: 0; --max: 100">
        <input
          ref="slider"
          :title="title"
          type="range"
          :step="step"
          :min="min"
          :max="max"
          v-model.number="value"
          @change="onSliderChange"
        />
      </div>
    </div>
    <div>
      <input
        :title="title"
        type="text"
        pattern="[0-9]."
        v-model="valueText"
        @keydown="onFieldKeydown"
        @change="onFieldChange"
        class="w-110 mr-1 text-right"
      />
      <span>kr.</span>
    </div>
  </div>
</template>

<style>
.slider {
  --ticksThickness: 1px;
  --ticksHeight: 10px;
  --ticksColor: silver;
  --marginX: 48px;

  width: calc(100% - 2 * var(--marginX));
  margin: 0 var(--marginX);
  display: inline-block;
  background: silver;
  background: linear-gradient(to right, var(--ticksColor) var(--ticksThickness), transparent 1px) repeat-x;
  background-size: calc(100% / ((var(--max) - var(--min)) / var(--step)) - 0.1%) var(--ticksHeight);
  background-position: 0 bottom;
  position: relative;
}

.slider > input {
  width: calc(100% + 8px);
  margin: 0 -7px;
}

.slider input {
  position: relative;
}

.slider input:before,
.slider input:after {
  position: absolute;
  top: 25px;
  font-size: 12px;
}

.slider input:before {
  content: '0';
  left: 3px;
}

.slider input:after {
  right: 0;
  transform: translateX(50%);
}

.slider input[max='250000']:after {
  content: '250000';
}

.slider input[max='10000000']:after {
  content: '10000000';
}

input[type='range'] {
  -webkit-appearance: none;
  margin-right: 15px;
  height: 7px;
  background: #efefef;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  background-image: linear-gradient(#5e7360, #5e7360);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #5e7360;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #5e7360;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #5e7360;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:hover {
  background: #5e7360;
}

input[type='range']::-moz-range-thumb:hover {
  background: #5e7360;
}

input[type='range']::-ms-thumb:hover {
  background: #5e7360;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

@media screen and (max-width: 768px) {
  .slider {
    --marginX: 8px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      value: 0,
      valueText: '0',
    }
  },
  methods: {
    onSliderChange() {
      this.$emit('value-change', this.value)
    },
    onFieldKeydown(event: KeyboardEvent) {
      const allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Home',
        'End',
      ]

      if (!event.ctrlKey && !allowedKeys.includes(event.key)) {
        event.preventDefault()
      }
    },
    onFieldChange() {
      let value = parseInt(this.valueText.replace(/[^0-9]/g, ''))

      if (isNaN(value)) {
        value = this.min;
      }

      if (value < this.min) {
        value = this.min
      } else if (value > this.max) {
        value = this.max
      }

      this.value = value

      this.$emit('value-change', this.value)
    },
  },
  watch: {
    value(newValue) {
      const slider = this.$refs.slider as HTMLInputElement
      slider.style.backgroundSize = (newValue / this.max) * 100 + '% 100%'
      this.valueText = newValue.toLocaleString('da-DK')
    },
  },
})
</script>
