<template>
  <!-- Header -->
  <header class="container flex-v-center header">
    <div class="grid">
      <div class="span12 flex-v-center">
        <a href="/" title="Gå til top" data-gtm-action="header navigation" data-gtm-label="hjem">
          <inline-svg class="logo" :src="usePrefixAssetUrl(norliLogo)" width="150" height="30" aria-label="Norli logo"
        /></a>

        <nav class="d-flex flex-h-right nav">
          <ul :class="['d-flex flex-v-center menu', { 'menu--mobile': isMobileHeader, active: isMenuActive }]">
            <li v-for="(link, index) in props.links" :key="index">
              <a
                class="p-2"
                :href="`/${link.href}`"
                :title="link.title"
                data-gtm-action="header navigation"
                :data-gtm-label="link.title"
                v-text="link.title"
              />
            </li>
          </ul>
          <a
            class="button button--cta ml-4"
            href="/mit-norli"
            data-gtm-action="header navigation"
            data-gtm-label="log på"
            >Log på</a
          >
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import norliLogo from '@/assets/svg/norli-logo.svg'

import { usePrefixAssetUrl } from '@/composables/usePrefixAssetUrl'
import { computed } from 'vue'
import { useStore } from 'vuex'

interface IProps {
  links?: {
    title: string
    href: string
  }[]
}

const store = useStore()

const isMobileHeader = computed(() => {
  return store.state.windowWidth < 920
})

const isMenuActive = computed(() => {
  return store.state.isMenuOpen
})

const props = defineProps<IProps>()
</script>

<style scoped></style>
