<template>
  <div class="span12 px-1 calculator">
    <h3>NORLI OMKOSTNINGSBEREGNER</h3>
    <p>Pensionsprodukter udbydes af Norli Liv og Pension A/S.</p>
    <p>Opdateret d. 11. juni 2024</p>
    <p>
      Her kan du beregne, hvor meget du skal betale i årlige omkostninger, og se, hvordan din pensionsopsparing udvikler
      sig, hvis du opretter en pensionsordning hos Norli.
    </p>
    <p>I Norli har vi lave omkostninger. Kontakt os, og få et konkret tilbud på en pensionsordning.</p>
    <p>
      <span style="color: red; font-weight: bold">! </span>
      <button type="button" class="link-btn" @click="showPopup = true">Læs forudsætningerne for beregningen</button>
    </p>
    <div v-show="showPopup" class="popup-container">
      <div class="popup-overlay" @click="showPopup = false"></div>
      <div class="popup">
        <button type="button" class="close-btn" @click="showPopup = false">🗙</button>
        <div class="popup-content">
          <h3>Forudsætninger for beregningen</h3>
          <p>
            For at du kan sammenligne omkostninger på tværs af pensionsselskaber, har forsikringsbranchen via Forsikring
            &amp; Pension aftalt hvilke forudsætninger, der skal ligge til grund for beregningen.
          </p>
          <b>Det forudsættes:</b>
          <ul>
            <li>at du opretter en ny pensionsordning i Norli</li>
            <li>at din eventuelle årlige indbetaling sker første gang ved oprettelsen</li>
            <li>at du ikke er fritaget for at betale pensionsafkastskat</li>
            <li>
              at du ved en overførsel af din nuværende ordning også indbetaler årligt til ordningen, og at din nuværende
              ordning overføres skatte- og afgiftsfrit (jf. pensionsbeskatningslovens § 41)
            </li>
            <li>
              at det er en obligatorisk ordning, hvis du vælger ”firma” i dine indtastninger. Der er tale om en
              obligatorisk ordning, når mindst 3 personer er omfattet af ordningen, og at den årlige indbetaling pr.
              person har en vis størrelse.
            </li>
          </ul>
          <b>Generelt om beregningen af omkostninger</b>
          <ul>
            <li>De anførte omkostninger er ca. beløb og viser kun 1. års omkostninger.</li>
            <li>De direkte omkostninger er baseret på aktuelle priser.</li>
            <li>De indirekte omkostninger er baseret på oplysninger for det seneste hele kalenderår.</li>
          </ul>
          <b>Når du sammenligner med andre selskaber</b><br />
          Vær opmærksom på, at de andre pensionsselskaber kan have produkter, der ikke er sammenlignelige med vores
          pensionsprodukter.
        </div>
      </div>
    </div>
    <div>
      <div class="input-group d-flex mb-4">
        <div class="input-label mr-5">
          <b class="mr-2">Hvilken pension ønsker du?</b>
          <div class="info-icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
            <div class="tooltip">
              Angiv, om du selv har oprettet pensionsordningen (Privatordning), eller om den er tegnet i
              ansættelsesforhold (Firmaordning).
            </div>
          </div>
        </div>
        <div class="flex-grow">
          <input
            id="ordningSelectOneRadioButtonPrivat"
            name="ordningSelectOneRadioButton"
            type="radio"
            :value="false"
            v-model="input.arbejdsgiver"
            checked
          />
          <label for="ordningSelectOneRadioButtonPrivat" class="mr-3"><span></span>Privat</label>
          <input
            id="ordningSelectOneRadioButtonFirma"
            name="ordningSelectOneRadioButton"
            type="radio"
            :value="true"
            v-model="input.arbejdsgiver"
          />
          <label for="ordningSelectOneRadioButtonFirma"><span></span>Firma</label>
        </div>
      </div>
      <div class="input-group d-flex mb-4">
        <div class="input-label">
          <b class="mr-2">Hvor meget vil du indbetale om året?</b>
          <div class="info-icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
            <div class="tooltip">Angiv, hvor meget du ønsker at indbetale til din pensionsopsparing om året.</div>
          </div>
        </div>
        <div class="d-flex flex-grow flex-col">
          <CalculatorSliderInput
            title="indbetaling"
            :min="0"
            :max="250000"
            :step="1000"
            @value-change="(value: number) => input.indbetaling = value"
          />
          <!-- <p class="error" v-show="firstSubmitMade && indbetalingError">
            Omkostninger kunne ikke regnes - prøv med en større indbetaling.
          </p> -->
        </div>
      </div>
      <div class="input-group d-flex mb-4">
        <div class="input-label">
          <b class="mr-2">Ønsker du at betale indskud?</b>
          <div class="info-icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
            <div class="tooltip">
              Ønsker du at indbetale et engangsbeløb ved oprettelsen, skal du angive beløbet her.
            </div>
          </div>
        </div>
        <div class="d-flex flex-grow flex-col">
          <CalculatorSliderInput
            title="indskud"
            :min="0"
            :max="250000"
            :step="1000"
            @value-change="(value: number) => input.indskud = value"
          />
          <!-- <p class="error" v-show="firstSubmitMade && indskudError">
            Engangsindbetaling skal være mindst {{ minOneTimePayment }} kr.
          </p>
          <p class="error" v-show="firstSubmitMade && indskudErrorOpsparing">
            Værdien er for lav - bør være mindst {{ minOneTimePaymentWithSavings }} kr.
          </p> -->
        </div>
      </div>
      <div class="input-group d-flex mb-4">
        <div class="input-label">
          <b class="mr-2">Hvilken værdi har din nuværende pension i andet selskab?</b>
          <div class="info-icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
            <div class="tooltip">
              Har du en pensionsopsparing i et andet selskab, som du ønsker at flytte til os, skal den nuværende saldo
              på opsparingen angives her. Du kan finde saldoen på pensionsinfo.dk.
            </div>
          </div>
        </div>
        <CalculatorSliderInput
          title="opsparingAndetSelskab"
          :min="0"
          :max="10000000"
          :step="1000"
          @value-change="(value: number) => input.opsparingAndetSelskab = value"
        />
      </div>
      <div class="input-group d-flex mb-4">
        <div class="input-label">
          <b class="mr-2">Hvilken værdi har din nuværende pension i Norli?</b>
          <div class="info-icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
            <div class="tooltip">
              Her kan du angive værdien af den pension, du allerede har i Norli. Du kan finde oplysningen ved at logge
              ind på Mit Norli, og se under Opsparing eller finde oplysningen på Pensionsinfo.dk
            </div>
          </div>
        </div>
        <CalculatorSliderInput
          title="opsparingINorli"
          :min="0"
          :max="10000000"
          :step="1000"
          @value-change="(value: number) => input.opsparingINorli = value"
        />
      </div>
    </div>

    <div class="table-container mb-2">
      <div class="w-fit min-w-full">
        <table class="w-full">
          <thead>
            <tr>
              <th colspan="3" class="bg-dark font-bold">Samlede omkostninger</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                Årets omkostninger i kr. (ÅOK)
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    ÅOK er et nøgletal, som opgøres efter en fælles standard for pensionsbranchen. Beløbet består af
                    dine direkte omkostninger på ordningen og din andel af de samlede indirekte omkostninger - det vil
                    sige alle dine omkostninger. Klik på ”Læs mere” nedenfor for at se yderligere detaljer.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.totalOmk.toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr>
              <td colspan="2">
                Årets omkostninger i procent (ÅOP)
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    ÅOP er et nøgletal, som opgøres efter en fælles standard for pensionsbranchen. Procenten viser, hvor
                    meget de samlede omkostninger udgør i forhold til din opsparing. Jo flere år og jo mere du
                    indbetaler til din pensionsopsparing, des lavere bliver procenten.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.totalOmkPct.toLocaleString('da-DK') }} %</td>
            </tr>
            <tr>
              <td colspan="2" class="bg-gray font-bold" style="border-right: none">Yderligere detaljer</td>
              <td class="bg-gray text-right" style="border-left: none">
                <button
                  type="button"
                  class="link-btn link-col__title"
                  :class="{ 'link-col__title--active': isSamledeOmkostningerOpen }"
                  @click="isSamledeOmkostningerOpen = !isSamledeOmkostningerOpen"
                >
                  <span v-if="!isSamledeOmkostningerOpen">Læs mere</span>
                  <span v-else>Skjul</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-show="isSamledeOmkostningerOpen" class="w-full">
          <thead>
            <tr>
              <th class="bg-dark font-bold">Årlige omkostninger</th>
              <th class="bg-dark font-bold">Kroner</th>
              <th class="bg-dark font-bold">Procent</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Administrationsomkostninger og gebyrer
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Beløbet viser de omkostninger og gebyrer, du betaler via din opsparing. Procenten viser, hvor meget
                    beløbet udgør i forhold til din opsparing.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ Math.abs(output.direkteOmk).toLocaleString('da-DK') }} kr.</td>
              <td class="text-right">{{ output.admOmkPct.toLocaleString('da-DK') }} %</td>
            </tr>
            <tr>
              <td>
                Investeringsomkostninger
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Beløbet viser din andel af de samlede investeringsomkostninger. Du betaler ikke beløbet direkte, men
                    indirekte, fordi investeringsomkostningerne fratrækkes investeringsafkastet. Procenten viser, hvor
                    meget omkostningerne udgør i forhold til din opsparing.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.invOmk.toLocaleString('da-DK') }} kr.</td>
              <td class="text-right">{{ output.invOmkPct.toLocaleString('da-DK') }} %</td>
            </tr>
            <tr>
              <td>
                Betaling for sikkerhed
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Beløbet viser din andel af betalingen for, at vi sikrer dine udbetalinger. Det vil sige, at det er
                    en betaling for, at vi stiller kapital til rådighed og påtager os en risiko for tab. Du betaler ikke
                    for det direkte, men indirekte. Procenten viser hvor meget betalingen udgør i forhold til din
                    samlede opsparing.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.sikkerhedsOmk.toLocaleString('da-DK') }} kr.</td>
              <td class="text-right">{{ output.sikkerhedsOmkPct.toLocaleString('da-DK') }} %</td>
            </tr>
            <tr>
              <td class="font-bold">ÅOK /ÅOP - I alt</td>
              <td class="text-right">
                {{ (Math.abs(output.direkteOmk) + output.invOmk + output.sikkerhedsOmk).toLocaleString('da-DK') }}
                kr.
              </td>
              <td class="text-right">
                {{ (output.admOmkPct + output.invOmkPct + output.sikkerhedsOmkPct).toLocaleString('da-DK') }} %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
   
    <div class="table-container mb-2 mt-3">
      <div class="w-fit min-w-full">
        <table class="w-full">
          <thead>
            <tr>
              <th colspan="2" class="bg-dark font-bold">Opsparingsoversigt</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-bold">
                Opsparing - primo
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Opsparingen - primo er det beløb, du har oplyst i ”Værdi (af nuværende ordning)”.
                  </div>
                </div>
              </td>
              <td class="text-right">
                {{ (input.opsparingAndetSelskab + input.opsparingINorli).toLocaleString('da-DK') }} kr.
              </td>
            </tr>
            <tr>
              <td>
                Årets udvikling
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">Forskellen mellem Opsparingen - ultimo og Opsparingen - primo</div>
                </div>
              </td>
              <td class="text-right">
                {{
                  calculateYearlyDevelopment().toLocaleString('da-DK')
                }}
                kr.
              </td>
            </tr>
            <tr>
              <td class="font-bold">
                Opsparing - ultimo
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Værdien af din ordning, når indbetalinger og rentetilvækst er tillagt primo opsparingen og
                    omkostninger, pensionsafkastskat og evt. arbejdsmarkedsbidrag er fratrukket. Det vil sige værdien af
                    opsparingen efter første år.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.opsparingUltimo.toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr>
              <td class="bg-gray font-bold" style="border-right: none">Yderligere detaljer</td>
              <td class="bg-gray text-right" style="border-left: none">
                <button
                  type="button"
                  class="link-btn link-col__title"
                  :class="{ 'link-col__title--active': isOpsparingsoversigtOpen }"
                  @click="isOpsparingsoversigtOpen = !isOpsparingsoversigtOpen"
                >
                  <span v-if="!isOpsparingsoversigtOpen">Læs mere</span>
                  <span v-else>Skjul</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-show="isOpsparingsoversigtOpen" class="w-full">
          <thead>
            <tr>
              <th colspan="2" class="bg-dark font-bold">Opsparingsoversigt i detaljer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-bold">
                Opsparing - primo
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Opsparingen - primo er det beløb, du har oplyst i ”Værdi (af nuværende ordning)”.
                  </div>
                </div>
              </td>
              <td class="text-right">
                {{ (input.opsparingAndetSelskab + input.opsparingINorli).toLocaleString('da-DK') }} kr.
              </td>
            </tr>
            <tr>
              <td>
                Indbetalinger
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Årets samlede indbetalinger svarende til dine indtastninger i felterne ”Årlig indbetaling” og
                    ”Indskud”.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ (input.indbetaling + input.indskud).toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr v-if="input.arbejdsgiver">
              <td>
                Arbejdsmarkedsbidrag
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    På pensionsordninger tegnet i et ansættelsesforhold skal vi opkræve og videresende
                    arbejdsmarkedsbidrag (AMB) til SKAT, der for tiden er 8 %.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ (output.ambIndbetalling + output.ambIndskud).toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr>
              <td>
                Direkte omkostninger
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Beløbet viser de administrationsomkostninger og gebyrer, du betaler direkte via din opsparing.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.direkteOmk.toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr>
              <td>
                Renter
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Årets renter af opsparingen, indbetalinger m.m. Beløbet er vist før pensionsafkastskat.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.renteFoerPal.toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr>
              <td>
                Pensionsafkastskat
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">Pensionsafkastskat udgør 15,3 %. Beløbet betales til SKAT.</div>
                </div>
              </td>
              <td class="text-right">{{ output.pal.toLocaleString('da-DK') }} kr.</td>
            </tr>
            <tr>
              <td class="font-bold">
                Opsparing - ultimo
                <div class="info-icon ml-1" @mouseenter="showTooltip" @mouseleave="hideTooltip">
                  <div class="tooltip">
                    Værdien af din ordning, når indbetalinger og rentetilvækst er tillagt primo opsparingen og
                    omkostninger, pensionsafkastskat og evt. arbejdsmarkedsbidrag er fratrukket. Det vil sige værdien af
                    opsparingen efter første år.
                  </div>
                </div>
              </td>
              <td class="text-right">{{ output.opsparingUltimo.toLocaleString('da-DK') }} kr.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <i>Vi gør opmærksom på, at hvis sammentællingen ikke stemmer, skyldes det afrundinger</i>
    <div v-show="showSpinner" class="spinner"></div>
    <div class="toast" :class="{ 'toast--active': showToast }">Noget gik galt, prøv igen senere.</div>
  </div>
</template>

<style>
.calculator {
  position: relative;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

table,
td,
th {
  border: 1px solid #ccc !important;
  border-collapse: collapse;
  padding: 5px 10px !important;
  white-space: nowrap;
  text-align: left;
}

td {
  min-width: 130px;
}

td:last-child {
  width: 18%;
}

tr:nth-child(odd) td {
  background-color: #eef1ef;
}

.bg-dark {
  background-color: #5e7360 !important;
  color: #fff;
  border-color: #5e7360;
}

.bg-gray {
  background-color: #c7c7c7 !important;
  border-color: #ccc;
}

.w-full {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-250 {
  width: 250px;
}

.w-110 {
  width: 110px;
}

.min-w-full {
  min-width: 100%;
}

.h-48 {
  height: 48px;
}

.font-bold {
  font-weight: bold;
}

.input-label {
  width: 250px;
  display: flex;
  justify-content: space-between;
}

input[type='radio'] {
  display: none;
}

input[type='radio'] + label span {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -4px 6px 0 0;
  vertical-align: middle;
  position: relative;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 100%;
  cursor: pointer;
}

input[type='radio'] + label span:after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  top: -3px;
  left: -3px;
  border: 1px solid #000;
  border-radius: 100%;
  display: inherit;
}

input[type='radio']:checked + label span {
  background: #5e7360;
}

.link-btn {
  padding-left: 0;
  background: none;
  border: none;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  color: #222;
}

.info-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-align: center;
  background: #5e7360;
  border-radius: 100%;
  font-size: 14px;
  color: #fff;
  user-select: none;
  cursor: pointer;
  flex-shrink: 0;
}

.info-icon:after {
  content: '?';
}

.tooltip {
  width: 0;
  height: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: #fff;
  color: #000;
  text-align: center;
  font-weight: 400;
  border-radius: 5px;
  opacity: 0;
  cursor: auto;
  overflow: hidden;
  white-space: normal;
}

.tooltip--active {
  width: 250px;
  height: auto;
  padding: 10px;
  border: 1px solid #5e7360;
  opacity: 1;
}

.popup-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
}

.popup-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  opacity: 0.6;
}

.popup {
  max-width: 900px;
  margin: auto;
  padding: 20px 0 0 40px;
  position: relative;
  z-index: 1;
  background: #fff;
  box-shadow: 3px 3px 20px -7px #000;
  overflow: hidden;
}

.popup-content {
  max-height: calc(100vh - 20px);
  padding: 0 60px 40px 0;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 36px;
  background: none;
  border: none;
  color: #000;
  user-select: none;
  cursor: pointer;
}

.spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(/src/assets/svg/loading-spinner.svg) center/100px no-repeat rgba(255, 255, 255, 0.6);
}

.toast {
  width: 0;
  height: 0;
  padding: 0;
  max-width: 600px;
  position: fixed;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background: #d74444;
  color: #fff;
  font-weight: bold;
  text-align: center;
  opacity: 0;
}

.toast--active {
  width: calc(100% - 20px);
  height: auto;
  padding: 12px 36px;
  transition: opacity 0.5s;
  opacity: 1;
}

.error {
  margin: 10px 0 5px 48px;
  color: #d74444;
}

@media screen and (max-width: 768px) {
  .input-group {
    flex-direction: column;
  }

  .input-label {
    width: auto;
    margin-bottom: 10px;
    display: block;
  }

  .popup {
    max-height: calc(100vh - 20px);
  }

  .error {
    margin: 10px 0 5px 0;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import CalculatorSliderInput from './CalculatorSliderInput.vue'

interface inputModel {
  arbejdsgiver: boolean
  indbetaling: number
  indskud: number
  opsparingAndetSelskab: number
  opsparingINorli: number
}

interface outputModel {
  totalOmk: number
  totalOmkPct: number
  direkteOmk: number
  admOmkPct: number
  invOmk: number
  invOmkPct: number
  sikkerhedsOmk: number
  sikkerhedsOmkPct: number
  ambIndbetalling: number
  ambIndskud: number
  renteFoerPal: number
  pal: number
  opsparingUltimo: number
}

interface outputResponseModel {
  omkostningsRegnerResult: outputModel
}

export default defineComponent({
  components: { CalculatorSliderInput },
  data() {
    const input: inputModel = {
      arbejdsgiver: false,
      indbetaling: 0,
      indskud: 0,
      opsparingAndetSelskab: 0,
      opsparingINorli: 0,
    }

    const output: outputModel = {
      totalOmk: 0,
      totalOmkPct: 0,
      direkteOmk: 0,
      admOmkPct: 0,
      invOmk: 0,
      invOmkPct: 0,
      sikkerhedsOmk: 0,
      sikkerhedsOmkPct: 0,
      ambIndbetalling: 0,
      ambIndskud: 0,
      renteFoerPal: 0,
      pal: 0,
      opsparingUltimo: 0,
    }

    return {
      input,
      output,
      showPopup: false,
      showSpinner: false,
      showToast: false,
      isSamledeOmkostningerOpen: false,
      isOpsparingsoversigtOpen: false,
      minPayment: 1011,
      minOneTimePayment: 11900,
      minOneTimePaymentWithSavings: 13000,
      firstSubmitMade: false,
    }
  },
  computed: {
    indbetalingError(): boolean {
      if (this.input.opsparingINorli > 0) {
        return false
      }

      return this.input.indbetaling + this.input.indskud < this.minPayment
    },
    indskudError(): boolean {
      if (this.input.opsparingINorli > 0) {
        return false
      }

      if (this.input.indbetaling === 0) {
        return this.input.indskud < this.minOneTimePayment
      }

      return false
    },
    indskudErrorOpsparing(): boolean {
      if (this.input.indbetaling > 0) {
        return false
      }
      
      if (this.input.opsparingINorli > 0 && this.input.indskud < this.minOneTimePaymentWithSavings) {
        return true
      }

      return false
    },
    missingInfoError(): boolean {
      return this.input.indbetaling === 0 && this.input.indskud === 0 && this.input.opsparingINorli === 0;
    }
  },
  methods: {
    getCalculation(): Promise<outputResponseModel> {
      const body = JSON.stringify({ funktion: 'OmkostningsRegner', ...this.input })

      return fetch('/api/norli/v1/omkostningsmaaler', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }).then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('Error when fetching data from the API')
        }
      })
    },
    showTooltip(event: Event) {
      const target = event.target as HTMLElement
      const rect = target.getBoundingClientRect()
      const tooltip = target.firstElementChild as HTMLElement

      tooltip.removeAttribute('style')
      tooltip.classList.add('tooltip--active')

      tooltip.style.top = `${rect.y - tooltip.clientHeight}px`

      const x = rect.x - tooltip.clientWidth / 2

      if (x < 0) {
        tooltip.style.left = '10px'
      } else if (x + tooltip.clientWidth > window.innerWidth) {
        tooltip.style.left = `${window.innerWidth - tooltip.clientWidth - 10}px`
      } else {
        tooltip.style.left = x + 'px'
      }
    },
    hideTooltip(event: Event) {
      const target = event.target as HTMLElement
      const tooltip = target.firstChild as HTMLElement
      tooltip.classList.remove('tooltip--active')
    },
    calculateYearlyDevelopment() {
      var calc = this.output.opsparingUltimo - this.input.opsparingAndetSelskab - this.input.opsparingINorli;
      return calc > 0 ? calc : 0;
    }
  },
  mounted() {
    this.$watch(
      () => this.input,
      () => {
        this.firstSubmitMade = true

        if (this.missingInfoError) {
          this.output.totalOmk = 0;
          this.output.totalOmkPct = 0;
          this.output.opsparingUltimo = 0;

          return
        }

        if (this.indskudError || this.indbetalingError) {
          return
        }

        this.showSpinner = true

        this.getCalculation()
          .then((output) => {
            this.output = output.omkostningsRegnerResult
          })
          .catch((err: Error) => {
            console.error(err)
            this.showToast = true
            setTimeout(() => {
              this.showToast = false
            }, 5000)
          })
          .finally(() => {
            this.showSpinner = false
          })
      },
      {
        deep: true,
      }
    )
  },
})
</script>
